/**
 * Renders a GTMTextBlock that contains an image, heading,
 * description, and links to the content.
 */
/** @jsx jsx */
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import Image from '../../atoms/Image/Image';
import Link from '../../atoms/Link/Link';
import Text from '../../atoms/Text/Text';
import { Heading3 } from '../../atoms/Headings/Headings';
import sanityImage from '../../../utils/sanityImage';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';

/**
 * The <GTMTextBlock /> Component
 */
const GTMTextBlock = ({
  description,
  headingPrefixText,
  headingText,
  image,
  imageWidth,
  link,
}) => {
  const imageCss = css`
    width: ${uc(imageWidth)};
    margin-bottom: ${uc('20px')};
  `;

  const gtmTextBlockCss = css`
    flex: 1 0 33%;
    line-height: ${lineHeights.copy};

    ${Text} {
      margin-bottom: ${uc('10px')};
      color: ${colors.white};
    }

    @media (${breakpoints.tablet}) {
      flex: 0 0 50%;
      width: 100%;
    }

    @media (${breakpoints.mobile}) {
      flex: 0 0 auto;
      width: 100%;
    }
  `;

  const heading3Css = css`
    color: ${colors.mediumGray};
    line-height: ${lineHeights.copy};

    span {
      color: ${colors.white};
      text-decoration: none;
    }
  `;

  const linkCss = css`
    color: ${colors.accent};
    font-weight: ${fontWeights.semiBold};
    font-family: ${fontFamilies.proximaSoft};
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      color: ${colors.accentHover};
    }
  `;

  const renderLink = () => {
    if (link) {
      return (
        <Link css={linkCss} to={link}>
          {link.text}
        </Link>
      );
    }
    return null;
  };

  return (
    <div css={gtmTextBlockCss}>
      <div css={imageCss}>
        <Image
          src={sanityImage(image)
            .auto('format')
            .url()}
          alt={image.alt}
        />
      </div>
      <Heading3 css={heading3Css}>
        {headingPrefixText && headingPrefixText}
        <span>{headingText}</span>
      </Heading3>
      <Text>{description}</Text>
      {renderLink(link)}
    </div>
  );
};

/**
 * Prop types
 */
GTMTextBlock.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  imageWidth: PropTypes.string,
  headingPrefixText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  headingText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

/**
 * Default props
 */
GTMTextBlock.defaultProps = {
  image: '',
  imageWidth: '120px',
  headingPrefixText: false,
  link: '/',
};

export default GTMTextBlock;
