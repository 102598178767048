/**
 * Renders a GTM Text Block into a section
 * nice to have: maybe make this generic for future types?
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import DeprecatedGrid from '../../atoms/Grid/DeprecatedGrid';
import GTMTextBlock from '../../molecules/GTMTextBlock/GTMTextBlock';
import PageWrapper from '../PageWrapper/PageWrapper';
import Section from '../../molecules/Section/Section';
import {
  colors,
  sectionSettings,
  unitConverter as uc,
} from '../../../styles/base';

const GTMSection = ({ gtmTextBlocks, sectionStyles }) => {
  const gtmSectionCss = css`
    margin: 0 auto;
    background-color: ${sectionStyles && sectionStyles.backgroundColor
      ? sectionSettings[sectionStyles.backgroundColor]
      : colors.darkGray.three};
    background-size: contain;
  `;

  const gridCss = css`
    > * {
      padding: ${uc('20px')};
    }
  `;

  const renderedTextBlocks = gtmTextBlocks.map(block => (
    <GTMTextBlock key={`gtm-text-block-component-${block._key}`} {...block} />
  ));

  return (
    <Section css={gtmSectionCss} sectionStyles={sectionStyles}>
      <PageWrapper>
        <DeprecatedGrid css={gridCss}>{renderedTextBlocks}</DeprecatedGrid>
      </PageWrapper>
    </Section>
  );
};

/**
 * Prop types
 */
GTMSection.propTypes = {
  gtmTextBlocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

GTMSection.defaultProps = {
  sectionStyles: {},
};

export default GTMSection;
